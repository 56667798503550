<div class="date-selector">
  <div class="date-selector__value">
    <span class="date-selector__value-label" [ngClass]="{ 'date-selector__value-label--selected': !!selectedDate() }">
      {{ label() }}
    </span>
    <span class="date-selector__value-date">{{ selectedDate() | date: 'dd.MM.yyyy' }}</span>
  </div>

  @if (!selectedDate()) {
    <button
      class="date-selector__button"
      mat-icon-button
      [disabled]="isDisabled()"
      (click)="toggleCalendarVisibility()"
      type="button"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
    >
      <mat-icon>today</mat-icon>
    </button>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen()"
      [cdkConnectedOverlayHasBackdrop]="true"
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      (overlayOutsideClick)="toggleCalendarVisibility()"
    >
      <div cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
        <mat-calendar
          class="mp-facet-date-selector__calendar"
          [(selected)]="selectedDate"
          (selectedChange)="onSelectedDateChange($event)"
        />
      </div>
    </ng-template>
  } @else {
    <button class="date-selector__button" mat-icon-button (click)="onSelectedDateClear()" type="button">
      <mat-icon>close</mat-icon>
    </button>
  }
</div>
