import { Pipe, PipeTransform } from '@angular/core';

import { DateRange } from '../../models';

@Pipe({
  name: 'mpSelectedDateRange',
  standalone: true,
})
export class SelectedDateRangePipe implements PipeTransform {
  transform({ dateFrom, dateTo }: DateRange): string {
    if (!dateFrom && !dateTo) {
      return '';
    }

    if (!dateTo) {
      return `ab ${dateFrom}`;
    }

    if (!dateFrom) {
      return `bis ${dateTo}`;
    }

    return `von ${dateFrom} bis ${dateTo}`;
  }
}
