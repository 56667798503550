import { Directive, TemplateRef } from '@angular/core';

import { AttributeFacet } from '@mp/shared/facets/domain';

/**
 * The context of the bucket template.
 */
export interface AttributeFacetBucketContext<TData extends object | unknown = unknown> {
  /**
   * The bucket object.
   */
  $implicit: AttributeFacet.BucketLevel1<TData>;
  /**
   * The bucket object.
   */
  bucket: AttributeFacet.BucketLevel1<TData>;
}

@Directive({
  selector: '[mpAttributeFacetBucket]',
  standalone: true,
})
export class AttributeFacetBucketDirective<TData extends object | unknown = unknown> {
  constructor(public readonly template: TemplateRef<AttributeFacetBucketContext<TData>>) {}
}
