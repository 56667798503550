<div class="facet--panel" [class.is--expanded]="isExpanded">
  <div class="facet--head" (click)="toggleExpanded()">
    @if (icon) {
      <div class="facet--icon">
        <mat-icon class="material-icons-outlined" [mpIcon]="icon" />
      </div>
    }

    <div class="facet--title">
      @if (typeFacet && typeFacet.buckets.length && isExpanded) {
        @if (typeFacet.buckets.length > 1) {
          <div>
            <mp-select
              class="facet--type-select"
              [options]="bucketSelectOptions()"
              [selectedValue]="selectedTypeBucket"
              (selectedValueChange)="handleTypeChange($event)"
              [customCompareWithFunction]="compareByVal"
              (click)="$event.stopPropagation()"
            />
          </div>
        } @else {
          <span>{{ typeFacet.buckets[0].label }}</span>
        }
      } @else {
        <span>{{ (typeFacet ? selectedTypeBucket?.label : '') || title }}</span>
      }
    </div>

    @if (!isExpanded && showSelectionInfo && selectedBucket) {
      <div class="facet--selection-info truncate">
        {{ selectedBucket.label }}
      </div>
    }

    <div class="facet--actions">
      @if (isExpanded && selectedBucket) {
        <button
          mat-icon-button
          class="facet--clear-button facet--btn"
          aria-label="Clear filter"
          title="Auswahl entfernen"
          (click)="$event.stopPropagation(); clearSelection()"
        >
          <mat-icon>playlist_remove</mat-icon>
        </button>
      }

      @if (searchable && isExpanded) {
        <button
          mat-icon-button
          class="facet--search-button facet--btn"
          aria-label="Toggle Search"
          (click)="$event.stopPropagation(); toggleSearchExpanded()"
        >
          <mat-icon>{{ isSearchExpanded ? 'close' : 'search' }}</mat-icon>
        </button>
      }

      <button class="facet--toggle-btn facet--btn" mat-icon-button aria-label="Expand Panel">
        <mat-icon>{{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
      </button>
    </div>
  </div>

  @if (searchable && isExpanded && isSearchExpanded) {
    <mp-filter-search-field
      #searchField
      class="facet--searchfield"
      [searchFieldPlaceholder]="searchFieldPlaceholder"
      [autocompleteControl]="autocompletePanel.matAutocomplete"
      (valueChange)="searchControl.setValue($event)"
    />

    <mp-autocomplete-panel
      #autocompletePanel
      [options]="filteredBucketsOptions() | slice: 0 : 50"
      [optionTemplate]="optionTemplate.template"
      (valueSelected)="handleSearchOptionClick($event)"
    />

    <ng-template
      #optionTemplate="mpTypedTemplate"
      mpTypedTemplate
      [contextType]="autocompletePanel.optionTemplateContextType"
      let-option
    >
      <div class="facet--search-field-option-result">
        <div class="facet--search-field-option-result-label truncate">
          <div class="facet--search-field-option-result-code truncate">
            {{ option.value.value }}
          </div>
          <span class="facet--search-field-option-result-txt truncate"> {{ option.label }}</span>
        </div>
        <span class="facet--search-field-option-result-count">
          {{ option.value.count }}
        </span>
      </div>
    </ng-template>
  }

  <div class="facet--list">
    <!-- At the moment, skip root node -->
    <!-- <ng-container *ngTemplateOutlet="bucket; context: { $implicit: facet.root, level: 0 }"></ng-container> -->
    @if (facet.root.children?.length) {
      @for (child of facet.root.children; track child.value) {
        <ng-container *ngTemplateOutlet="bucketTemplate; context: { $implicit: child, level: 1 }" />
      }
    } @else {
      <ng-content select=".facet--no-buckets" />
    }
  </div>
</div>

<!-- Bucket template -->
<ng-template #bucketTemplate let-bucket let-level="level">
  <div
    class="facet--bucket"
    [class.facet--bucket-selected]="bucket.selected"
    [class.facet--bucket-expanded]="isBucketExpanded(bucket)"
  >
    <div class="facet--bucket-head">
      <div class="facet--bucket-expand-icon" (click)="toggleBucketExpanded(bucket)">
        @if (bucket.children?.length > 0) {
          <mat-icon class="material-icons-outlined">
            {{ isBucketExpanded(bucket) ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
          </mat-icon>
        } @else {
          <svg
            class="facet--bucket-expand-icon-svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0.218262H1V7.7819H0V0.218262Z" />
            <path d="M0 7.7819V6.83644H8V7.7819H0Z" />
          </svg>
        }
      </div>

      <span
        class="facet--bucket-label truncate"
        (click)="handleBucketClick(bucket)"
        [title]="(tooltipSelector ?? getDefaultBucketTooltip)(bucket)"
      >
        {{ bucket.label }}
      </span>

      <span class="facet--bucket-count">
        {{ bucket.count | number }}
      </span>
    </div>

    @if (bucket.children?.length > 0 && isBucketExpanded(bucket)) {
      <div class="facet--bucket-children">
        @for (child of bucket.children; track child.value) {
          <ng-container *ngTemplateOutlet="bucketTemplate; context: { $implicit: child, level: level + 1 }" />
        }
      </div>
    }
  </div>
</ng-template>
