@for (bucketsGroup of facet.buckets; track bucketsGroup.value) {
  <mp-attribute-facet-buckets-group
    [bucketsGroup]="bucketsGroup"
    [isExpanded]="isExpanded(bucketsGroup.value)"
    [showSelectionInfo]="!!showSelectionInfo"
    [searchable]="!!searchable"
    [searchFieldPlaceholder]="searchFieldPlaceholder"
    [isSearchExpanded]="isSearchExpanded(bucketsGroup.value)"
    (isSearchExpandedChange)="storeSearchExpansionState(bucketsGroup.value, $event)"
    (isExpandedChange)="toggleExpanded(bucketsGroup)"
    (clearSelection)="clearSelection()"
    (showMoreClick)="showMore(bucketsGroup.value)"
    (searchTermChange)="onGroupFilterSearchTermChange(bucketsGroup.label)"
  >
    <mat-checkbox
      *mpAttributeFacetBucket="let bucket = bucket"
      class="facet--bucket"
      [checked]="isSelected(bucket.value)"
      (change)="toggleSelected(bucket, bucketsGroup.label)"
    >
      <span class="facet--bucket-label truncate" [title]="bucket.label">
        {{ bucket.label }}
      </span>
      <span class="facet--bucket-count">
        {{ bucket.count | number }}
      </span>
    </mat-checkbox>
  </mp-attribute-facet-buckets-group>
}
