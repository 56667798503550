import { NgModule } from '@angular/core';

import {
  AttributeFacetComponent,
  BoolFacetComponent,
  DateRangeFacetComponent,
  MultiSelectFacetComponent,
  SingleSelectFacetComponent,
  TreeFacetComponent,
} from './components';

@NgModule({
  imports: [
    AttributeFacetComponent,
    BoolFacetComponent,
    MultiSelectFacetComponent,
    SingleSelectFacetComponent,
    TreeFacetComponent,
    DateRangeFacetComponent,
  ],
  exports: [
    AttributeFacetComponent,
    BoolFacetComponent,
    MultiSelectFacetComponent,
    SingleSelectFacetComponent,
    TreeFacetComponent,
    DateRangeFacetComponent,
  ],
})
export class FacetsModule {}
