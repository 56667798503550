import type { TreeFacet } from '@mp/shared/facets/domain';

/**
 * Returns the given node and all of its children (deep) in order of appearance.
 * @param node The node to flatten.
 */
export function* flattenTreeFacetBucket<TData>(node: TreeFacet.Bucket<TData>): Iterable<TreeFacet.Bucket<TData>> {
  yield node;
  if (node.children) {
    for (const child of node.children) {
      yield* flattenTreeFacetBucket(child);
    }
  }
}
