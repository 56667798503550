<mp-list-base-facet
  [facet]="facet"
  [title]="filterName"
  [icon]="icon"
  [selectionKey]="selectionKey"
  [searchable]="searchable"
  [searchFieldPlaceholder]="searchFieldPlaceholder"
  [maxVisibleItems]="maxVisibleItems"
  (changed)="onSelectionChanged($event)"
  (expandedChange)="onExpandedChange($event)"
>
  <mat-radio-button
    *mpListFacetBucket="let bucket = bucket; let onChange = onChange"
    [name]="radioButtonName"
    class="facet--bucket"
    [class.selected]="bucket.selected"
    [checked]="bucket.selected"
    (change)="
      $event.source.checked &&
        onChange([selectedBucket ? selectedBucket.value : bucket.value, !selectedBucket], [bucket.value, true]);
      onValueSelect(bucket.label)
    "
  >
    <div class="facet--bucket-content truncate">
      <ng-container
        *ngTemplateOutlet="
          bucketTemplate ?? defaultBucketTemplate;
          context: { $implicit: bucket, bucket: bucket, collapsed: false }
        "
      />
    </div>
    <div class="facet--bucket-count">
      {{ bucket.count | number }}
    </div>
  </mat-radio-button>

  <!-- Collapsed value -->
  <div *ngIf="selectedBucket" class="facet--selection-info">
    <ng-container
      *ngTemplateOutlet="
        bucketTemplate ?? defaultBucketTemplate;
        context: { $implicit: selectedBucket, bucket: selectedBucket, collapsed: true }
      "
    />
  </div>
</mp-list-base-facet>

<ng-template #defaultBucketTemplate let-bucket let-collapsed="collapsed">
  <div class="facet--bucket-label truncate" [attr.title]="collapsed ? undefined : bucket.label">
    {{ bucket.label }}
  </div>
</ng-template>
