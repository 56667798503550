import { Directive, TemplateRef } from '@angular/core';

import { ListFacetBase } from '@mp/shared/facets/domain';

/**
 * The context of the bucket template.
 */
export interface ListFacetBucketContext<TData extends object | unknown = unknown> {
  /**
   * The bucket object.
   */
  $implicit: ListFacetBase.Bucket<TData>;
  /**
   * The bucket object.
   */
  bucket: ListFacetBase.Bucket<TData>;

  /**
   * The function to call on selection change.
   * @param changes The selection changes. `value` The value of the bucket. `selected` The selection state of the value.
   */
  onChange(...changes: [value: string, selected: boolean][]): void;
}

@Directive({
  selector: '[mpListFacetBucket]',
  standalone: true,
})
export class ListFacetBucketDirective<TData extends object | unknown = unknown> {
  constructor(public readonly template: TemplateRef<ListFacetBucketContext<TData>>) {}
}
