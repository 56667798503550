import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInput } from '@angular/material/legacy-input';
import { LetDirective } from '@ngrx/component';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { IconDirective } from '@core/shared/util';
import { FilterSearchFieldComponent } from '@core/ui';
import { ListFacetBase } from '@mp/shared/facets/domain';
import { FacetSelectionService, filterBuckets } from '@mp/shared/facets/util';

import { ListFacetBucketDirective } from './list-facet-bucket.directive';

let defaultSelectionKeyCounter = 0;
const expandedSelection = 'expanded';
const searchExpandedSelection = 'searchExpanded';
const searchFocusedSelection = 'searchFocused';

@Component({
  selector: 'mp-list-base-facet',
  standalone: true,
  templateUrl: './list-base-facet.component.html',
  styleUrls: ['./list-base-facet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    LetDirective,

    MatIconModule,
    MatLegacyButtonModule,

    IconDirective,
    FilterSearchFieldComponent,
  ],
})
export class ListBaseFacetComponent<TData extends object | unknown = unknown>
  implements OnInit, OnDestroy, AfterViewChecked
{
  private _facet!: ListFacetBase<TData>;
  private _searchable = false;
  private readonly _allBuckets$ = new BehaviorSubject<readonly ListFacetBase.Bucket<TData>[]>([]);
  private readonly _searchTerm$ = new BehaviorSubject('');
  private readonly _maxVisibleItems$ = new BehaviorSubject<number | null | undefined>(null);
  private readonly _showAll$ = new BehaviorSubject(false);
  private readonly _filteredBuckets$: Observable<ListFacetBase.Bucket<TData>[]>;
  private _selectionState!: FacetSelectionService.SelectionState;
  private _expansionState!: FacetSelectionService.SelectionState;

  readonly visibleBuckets$: Observable<ListFacetBase.Bucket<TData>[]>;
  readonly collapsedItemCount$: Observable<number>;
  readonly onSelectionChangeBound: typeof this.onSelectionChange;

  @HostBinding('attr.title')
  private readonly _title = '';

  @HostBinding('class')
  readonly class = 'mp-list-base-facet';

  constructor(
    private readonly selectionService: FacetSelectionService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this._filteredBuckets$ = combineLatest([this._allBuckets$, this._searchTerm$]).pipe(
      map(([buckets, searchTerm]) => {
        return Array.from(filterBuckets(buckets, searchTerm));
      }),
    );

    this.visibleBuckets$ = combineLatest([this._filteredBuckets$, this._maxVisibleItems$, this._showAll$]).pipe(
      map(([filteredBuckets, maxVisibleItems, showAll]) => {
        return showAll || maxVisibleItems == null || maxVisibleItems < 0 || maxVisibleItems >= filteredBuckets.length
          ? filteredBuckets
          : filteredBuckets.slice(0, maxVisibleItems);
      }),
    );

    this.collapsedItemCount$ = combineLatest([this._filteredBuckets$, this._maxVisibleItems$]).pipe(
      map(([filteredBuckets, maxVisibleItems]) => {
        if (maxVisibleItems == null || maxVisibleItems < 0) {
          return 0;
        }
        return Math.max(filteredBuckets.length - maxVisibleItems, 0);
      }),
    );

    this.onSelectionChangeBound = this.onSelectionChange.bind(this);
  }

  /**
   * The Facet object to display.
   */
  get facet(): ListFacetBase<TData> {
    return this._facet;
  }

  @Input()
  set facet(value: ListFacetBase<TData>) {
    this._facet = value;

    this._allBuckets$.next(value.buckets);

    if (this._selectionState) {
      this.setSelectedFromFacet(value);
    }
  }

  /**
   * Specifies the title of the filter component.
   */
  @Input()
  title?: string;

  /**
   * Specifies the icon of the filter component.
   */
  @Input()
  icon?: string;

  /**
   * If the current selection state should be stored, provide a unique key within the current instance
   * of `FacetSelectionService`. This is a constant value and must not be changed.
   */
  @Input()
  selectionKey?: string;

  /**
   * Specifies whether a search field is shown to search the buckets.
   */
  get searchable() {
    return this._searchable;
  }

  @Input()
  set searchable(value: BooleanInput) {
    this._searchable = coerceBooleanProperty(value);
  }

  /**
   * The placeholder of the search field.
   */
  @Input()
  searchFieldPlaceholder = 'Merkmale durchsuchen';

  /**
   * Sets the number of items visible when not expanded.
   */
  @Input()
  get maxVisibleItems() {
    return this._maxVisibleItems$.getValue();
  }

  set maxVisibleItems(value: NumberInput) {
    this._maxVisibleItems$.next(coerceNumberProperty(value, null));
  }

  /**
   * Emits on selection change and provides the selected values of the facet.
   */
  @Output() readonly changed = new EventEmitter<string[]>();

  /**
   * Emits on the filter expansion state change
   */
  @Output() readonly expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Emits on the search term change
   */
  @Output() readonly searchTermChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * The ref to the bucket template.
   */
  @ContentChild(ListFacetBucketDirective)
  readonly bucketDirective!: ListFacetBucketDirective<TData>;

  @ViewChild('searchField')
  searchField?: FilterSearchFieldComponent;

  private readonly fallbackSelectionKey: string = `listFacet${++defaultSelectionKeyCounter}`;

  get showAll() {
    return this._showAll$.getValue();
  }

  get searchTerm() {
    return this._searchTerm$.getValue();
  }

  ngOnInit(): void {
    const selectionKey = this.selectionKey ?? this.fallbackSelectionKey;
    this._selectionState = this.selectionService.getSelectionState(selectionKey);
    this._expansionState = this.selectionService.getSelectionState(this.getExpansionStateSelectionKey(selectionKey));

    this.setSelectedFromFacet(this._facet);
  }

  ngOnDestroy(): void {
    if (!this.selectionKey) {
      this.selectionService.deleteSelectionState(this.fallbackSelectionKey);
      this.selectionService.deleteSelectionState(this.getExpansionStateSelectionKey(this.fallbackSelectionKey));
    }
  }

  ngAfterViewChecked(): void {
    const searchFieldInput: MatLegacyInput | undefined = this.searchField?.inputElement;

    if (searchFieldInput && !searchFieldInput.focused && this._expansionState.isSelected(searchFocusedSelection)) {
      this._expansionState.toggleSelected(searchFocusedSelection, false);
      searchFieldInput.focus();
      this.cdr.detectChanges();
    }
  }

  get isExpanded(): boolean {
    return this._expansionState.isSelected(expandedSelection);
  }

  toggleExpanded(expanded?: boolean): void {
    this._expansionState.toggleSelected(expandedSelection, expanded);
    this.expandedChange.emit(this.isExpanded);
  }

  get isSearchExpanded(): boolean {
    return this._expansionState.isSelected(searchExpandedSelection);
  }

  toggleSearchExpanded(expanded?: boolean): void {
    this._expansionState.toggleSelected(searchExpandedSelection, expanded);
    this._expansionState.toggleSelected(searchFocusedSelection, expanded);
    this.executeSearch('');
  }

  executeSearch(searchTerm: string): void {
    this.searchTermChange.emit(searchTerm);
    this._searchTerm$.next(searchTerm);
  }

  onSelectionChange(...changes: [value: string, selected: boolean][]) {
    for (const [value, selected] of changes) {
      this._selectionState.toggleSelected(value, selected);
    }
    this.changed.emit(Array.from(this._selectionState.getSelectedValues()));
  }

  toggleShowAll(): void {
    this._showAll$.next(!this._showAll$.getValue());
  }

  private setSelectedFromFacet(facet: ListFacetBase<TData>): void {
    const selected: Record<string, boolean> = {};

    facet.buckets.forEach((b) => {
      selected[b.value] = b.selected;
    });

    this._selectionState.setSelected(selected);

    if (!this._expansionState.isSet(expandedSelection)) {
      this._expansionState.toggleSelected(expandedSelection, true);
    }
  }

  private getExpansionStateSelectionKey(selectionKey: string): string {
    return `${selectionKey}_$$expansion`;
  }
}
