import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DecimalPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { IconDirective } from '@core/shared/util';
import { BoolFacet } from '@mp/shared/facets/domain';

@Component({
  selector: 'mp-bool-facet',
  standalone: true,
  templateUrl: './bool-facet.component.html',
  styleUrls: ['./bool-facet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, DecimalPipe, MatCardModule, MatIconModule, MatSlideToggleModule, IconDirective],
})
export class BoolFacetComponent {
  private _facet!: BoolFacet;
  private _showCounts = false;

  @HostBinding('attr.title')
  private readonly _title = '';

  checked = false;

  /**
   * The BoolFacet object to display.
   */
  get facet(): BoolFacet {
    return this._facet;
  }

  @Input()
  set facet(value: BoolFacet) {
    this._facet = value;
    this.checked = value.activeBucket.selected;
  }

  /**
   * Specifies whether a badge with the number of results is shown on either side of the toggle button.
   */
  get showCounts() {
    return this._showCounts;
  }

  @Input()
  set showCounts(value: BooleanInput) {
    this._showCounts = coerceBooleanProperty(value);
  }

  /**
   * Specifies the title of the filter component.
   */
  @Input()
  title?: string;

  /**
   * Specifies the icon of the filter component.
   */
  @Input()
  icon?: string;

  /**
   * Emits on selection change and provides the selected state of the facet.
   */
  @Output() readonly changed = new EventEmitter<boolean>();

  toggleChecked(checked?: boolean): void {
    this.checked = checked ?? !this.checked;
    this.changed.emit(this.checked);
  }
}
