<mat-card class="facet--card" (click)="toggleChecked()">
  <div class="facet--icon" *ngIf="icon">
    <mat-icon class="material-icons-outlined" [mpIcon]="icon" />
  </div>

  <div class="facet--title truncate" [title]="title">
    {{ title }}
  </div>

  <div class="facet--toggle">
    <div class="facet--inactive-count" *ngIf="showCounts" [title]="facet.inactiveBucket.label">
      <span>{{ facet.inactiveBucket.count | number }}</span>
    </div>
    <mat-slide-toggle
      [checked]="checked"
      hideIcon
      (change)="toggleChecked($event.checked)"
      (click)="$event.stopPropagation()"
    />
    <div class="facet--active-count" *ngIf="showCounts" [title]="facet.activeBucket.label">
      <span>{{ facet.activeBucket.count | number }}</span>
    </div>
  </div>
</mat-card>
