<div class="facet--panel" [class.is--expanded]="isExpanded()">
  <div class="facet--head" (click)="toggleExpanded()">
    <h4 class="facet--title truncate" [title]="bucketsGroup().label">{{ bucketsGroup().label }}</h4>
    <div>
      @if (searchable() && isExpanded()) {
        <button
          mat-icon-button
          class="facet--search-button facet--btn"
          aria-label="Toggle Search"
          (click)="$event.stopPropagation(); toggleSearchExpanded()"
        >
          <mat-icon>{{ isSearchExpanded() ? 'close' : 'search' }}</mat-icon>
        </button>
      }

      @if (bucketSelectedOptionsQuantity()) {
        <button
          class="facet--clear-button facet--btn"
          mat-icon-button
          aria-label="Clear filter"
          title="Auswahl entfernen"
          (click)="$event.stopPropagation(); onClearSelection()"
        >
          <mat-icon>playlist_remove</mat-icon>
        </button>
      }

      @if (showSelectionInfo() && !isExpanded()) {
        <mp-filter-badge class="facet--selection-info" [value]="bucketSelectedOptionsQuantity()" />
      }

      <button class="facet--toggle-btn facet--btn" mat-icon-button aria-label="Expand Panel">
        <mat-icon>{{ isExpanded() ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
      </button>
    </div>
  </div>

  @if (searchable() && isExpanded() && isSearchExpanded()) {
    <mp-filter-search-field
      class="facet--searchfield"
      [value]="searchTerm()"
      [searchFieldPlaceholder]="searchFieldPlaceholder()"
      (search)="onSearchTermChange($event)"
    />
  }

  <div class="facet--list">
    @for (bucket of filteredBuckets(); track bucket.value) {
      <ng-container
        [ngTemplateOutlet]="bucketDirective.template"
        [ngTemplateOutletContext]="{
          $implicit: bucket,
          bucket: bucket
        }"
      />
    }

    @if (!filteredBuckets()?.length) {
      <p class="no-buckets-message">Keine Ergebnisse</p>
    }

    @if (bucketsGroup().hasMore) {
      <span class="facet--more" (click)="onShowMore()"> Mehr... </span>
    }
  </div>
</div>
