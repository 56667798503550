<mp-list-base-facet
  [facet]="facet"
  [title]="filterName"
  [icon]="icon"
  [selectionKey]="selectionKey"
  [searchable]="searchable"
  [searchFieldPlaceholder]="searchFieldPlaceholder"
  [maxVisibleItems]="maxVisibleItems"
  (changed)="onSelectionChanged($event)"
  (expandedChange)="onExpandedChange($event)"
  (searchTermChange)="onSearchTermChange($event)"
>
  <mat-checkbox
    *mpListFacetBucket="let bucket = bucket; let onChange = onChange"
    class="facet--bucket"
    [class.selected]="bucket.selected"
    [checked]="bucket.selected"
    (change)="onChange([bucket.value, $event.source.checked]); onValueSelect(bucket.label, $event.source.checked)"
  >
    <div class="facet--bucket-content truncate">
      <ng-container
        *ngTemplateOutlet="bucketTemplate ?? defaultBucketTemplate; context: { $implicit: bucket, bucket: bucket }"
      />
    </div>
    <div class="facet--bucket-count">
      {{ bucket.count | number }}
    </div>
  </mat-checkbox>

  <!-- Remove Filter button -->
  @if (selectionCount > 0) {
    <button
      mat-icon-button
      class="facet--clear-button facet--btn"
      aria-label="Clear filter"
      title="Auswahl entfernen"
      (click)="$event.stopPropagation(); clearSelection()"
    >
      <mat-icon>playlist_remove</mat-icon>
    </button>
  }

  <!-- Collapsed counter -->
  @if (showSelectionInfo) {
    <div class="facet--selection-info">
      <ng-content select="[additional-context-selection-info]" />
      <mp-filter-badge [value]="selectionCount" />
    </div>
  }

  <ng-content select="[additional-context-filter]" ngProjectAs="[additional-context-filter]" />
</mp-list-base-facet>

<ng-template #defaultBucketTemplate let-bucket>
  <div class="facet--bucket-label truncate" [title]="bucket.label">
    {{ bucket.label }}
  </div>
</ng-template>
