<div class="facet--panel" [class.is--expanded]="isExpanded">
  <div class="facet--head" (click)="toggleExpanded()">
    @if (icon(); as icon) {
      <div class="facet--icon">
        <mat-icon class="material-icons-outlined" [mpIcon]="icon" />
      </div>
    }

    <div class="facet--title">{{ title() }}</div>

    @if (!isExpanded && (dateFrom() || dateTo())) {
      <div class="facet--selection-info truncate">
        <ng-container
          *ngrxLet="{
            dateFrom: (dateFrom() | date: 'dd.MM.yyyy') ?? undefined,
            dateTo: (dateTo() | date: 'dd.MM.yyyy') ?? undefined
          } as dateRange"
        >
          <span *ngrxLet="dateRange | mpSelectedDateRange as selectedDateRange" [title]="selectedDateRange">
            {{ selectedDateRange }}
          </span>
        </ng-container>
      </div>
    }

    <div class="facet--actions">
      @if (isExpanded && (dateFrom() || dateTo())) {
        <button
          class="facet--clear-button facet--btn"
          mat-icon-button
          aria-label="Clear filter"
          title="Auswahl entfernen"
          (click)="$event.stopPropagation(); clearDateRangeSelection()"
        >
          <mat-icon>playlist_remove</mat-icon>
        </button>
      }

      <button class="facet--toggle-btn facet--btn" mat-icon-button aria-label="Expand Panel">
        <mat-icon>
          {{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
        </mat-icon>
      </button>
    </div>
  </div>

  <div class="facet--list">
    <mp-facet-date-selector
      class="facet--bucket"
      [(selectedDate)]="dateFrom"
      [label]="getDateFromSelectorLabel()"
      (selectedDateChange)="emitDateRangeChange()"
    />

    @if (showDateTo()) {
      <mp-facet-date-selector
        class="facet--bucket"
        [(selectedDate)]="dateTo"
        label="bis"
        (selectedDateChange)="emitDateRangeChange()"
      />
    }
  </div>
</div>
