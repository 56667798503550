import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  ModelSignal,
  OnDestroy,
  OnInit,
  OutputEmitterRef,
  input,
  model,
  output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { LetDirective } from '@ngrx/component';

import { IconDirective } from '@core/shared/util';
import { FacetSelectionService } from '@mp/shared/facets/util';

import { DateRange } from '../../models';

import { FacetDateSelectorComponent } from './facet-date-selector/facet-date-selector.component';
import { SelectedDateRangePipe } from './selected-date-range.pipe';

let defaultSelectionKeyCounter = 0;
const expandedSelection = 'expanded';

@Component({
  selector: 'mp-date-range-facet',
  standalone: true,
  templateUrl: './date-range-facet.component.html',
  styleUrl: './date-range-facet.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DatePipe,
    FormsModule,
    LetDirective,

    MatIconModule,
    MatLegacyButtonModule,

    IconDirective,
    FacetDateSelectorComponent,
    SelectedDateRangePipe,
  ],
})
export class DateRangeFacetComponent implements OnInit, OnDestroy {
  readonly title: InputSignal<string> = input.required<string>();

  readonly icon: InputSignal<string | undefined> = input<string | undefined>();

  readonly dateFrom: ModelSignal<string | undefined> = model<string | undefined>();

  readonly dateTo: ModelSignal<string | undefined> = model<string | undefined>();

  readonly showDateTo: InputSignal<boolean> = input<boolean>(true);

  readonly selectionKey: InputSignal<string> = input<string>('');

  readonly dateRangeChange: OutputEmitterRef<DateRange> = output<DateRange>();

  private _expansionState!: FacetSelectionService.SelectionState;

  private readonly fallbackSelectionKey: string = `dateRangeFacet${++defaultSelectionKeyCounter}`;

  constructor(private readonly selectionService: FacetSelectionService) {}

  ngOnInit(): void {
    this.setupExpansionState();
  }

  private setupExpansionState(): void {
    const selectionKey: string = this.selectionKey() ?? this.fallbackSelectionKey;
    this._expansionState = this.selectionService.getSelectionState(selectionKey);

    if (!this._expansionState.isSet(expandedSelection)) {
      this._expansionState.toggleSelected(expandedSelection, true);
    }
  }

  ngOnDestroy(): void {
    if (!this.selectionKey()) {
      this.selectionService.deleteSelectionState(this.fallbackSelectionKey);
    }
  }

  get isExpanded(): boolean {
    return this._expansionState.isSelected(expandedSelection);
  }

  toggleExpanded(expanded?: boolean): void {
    this._expansionState.toggleSelected(expandedSelection, expanded);
  }

  clearDateRangeSelection(): void {
    this.dateFrom.set(undefined);
    this.dateTo.set(undefined);
    this.emitDateRangeChange();
  }

  emitDateRangeChange(): void {
    this.dateRangeChange.emit({ dateFrom: this.dateFrom(), dateTo: this.dateTo() });
  }

  getDateFromSelectorLabel(): string {
    if (!this.showDateTo()) return 'ab';
    return this.dateTo() || !this.dateFrom() ? 'von' : 'ab';
  }
}
