export function filterBuckets<T extends { label: string; selected: boolean }>(
  buckets: readonly T[],
  searchTerm: string,
): readonly T[] {
  searchTerm = searchTerm.trim().toLowerCase();

  if (!searchTerm) {
    return buckets;
  }

  return buckets.filter((bucket) => bucket.selected || bucket.label.trim().toLowerCase().includes(searchTerm));
}
