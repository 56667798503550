<div class="facet--panel" [class.is--expanded]="isExpanded">
  <div class="facet--head" (click)="toggleExpanded()">
    @if (icon) {
      <div class="facet--icon">
        <mat-icon class="material-icons-outlined" [mpIcon]="icon" />
      </div>
    }

    <div class="facet--title">
      <span>{{ title }}</span>
    </div>

    @if (!isExpanded) {
      <ng-content select=".facet--selection-info" />
    }

    @if (isExpanded) {
      <ng-content select=".facet--btn" />
    }

    @if (searchable && isExpanded) {
      <button
        mat-icon-button
        class="facet--search-button facet--btn"
        aria-label="Toggle Search"
        (click)="$event.stopPropagation(); toggleSearchExpanded()"
      >
        <mat-icon>{{ isSearchExpanded ? 'close' : 'search' }}</mat-icon>
      </button>
    }

    <button class="facet--toggle-btn facet--btn" mat-icon-button aria-label="Expand Panel">
      <mat-icon>{{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
    </button>
  </div>

  @if (searchable && isExpanded && isSearchExpanded) {
    <mp-filter-search-field
      #searchField
      class="facet--searchfield"
      [searchFieldPlaceholder]="searchFieldPlaceholder"
      (search)="executeSearch($event)"
    />
  }

  <div class="facet--list">
    <div *ngrxLet="visibleBuckets$ | async as visibleBuckets">
      @for (bucket of visibleBuckets; track bucket.value) {
        <ng-container
          [ngTemplateOutlet]="bucketDirective.template"
          [ngTemplateOutletContext]="{
            $implicit: bucket,
            bucket: bucket,
            onChange: onSelectionChangeBound
          }"
        />
      }

      @if (!visibleBuckets?.length) {
        <p class="no-buckets-message">Keine Ergebnisse</p>
      }
    </div>

    <div class="facet--additional-context-filter">
      <ng-content select="[additional-context-filter]" />
    </div>
  </div>

  <ng-container *ngrxLet="collapsedItemCount$ as collapsedItemCount">
    @if (collapsedItemCount > 0 && isExpanded) {
      <span class="facet--more" (click)="toggleShowAll()">
        {{ showAll ? 'weniger anzeigen' : collapsedItemCount + ' weitere' }}
      </span>
    }
  </ng-container>
</div>
